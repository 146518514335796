import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import * as Papa from 'papaparse';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-csv-viewer',
  templateUrl: './csv-viewer.component.html',
  styleUrls: ['./csv-viewer.component.scss']
})
export class CsvViewerComponent implements OnInit {

  csvUrl: string = '';
  csvData: any[];
  pageSize = 100;
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  pageSizeOptions: number[] = [100 , 200 , 500];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CsvViewerComponent>,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.csvUrl = this.data.csvUrl;
    this.fetchCsv();
  }

  fetchCsv() {
    this.fetchCsvData(this.csvUrl).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.displayedColumns = Object.keys(data[0]);
    });
  }

  fetchCsvData(url: string): Observable<any[]> {
    return new Observable<any[]>(observer => {
      this.http.get(url, { responseType: 'text' }).subscribe(data => {
        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: result => {
            if (result.data.length > 0) {
              // Get non-empty column headers
              const validColumns = result.meta.fields.filter(field => field && field.trim() !== "");
              // Remove empty columns from each row
              const cleanedData = result.data.map(row => {
                return Object.fromEntries(
                  Object.entries(row).filter(([key]) => validColumns.includes(key))
                );
              });
              observer.next(cleanedData);
            } else {
              observer.next([]); // Return empty array if no valid data
            }
            observer.complete();
          },
          error: error => {
            observer.error(error);
          }
        });
      });
    });
  }

}
