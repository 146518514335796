import { notEmpty, required } from '@rxweb/reactive-form-validators';

export class Company {
  id: number;
  trialStatus: false;
  remainingTrialDays: number;
  @notEmpty()
  companyName: string;
  accessId: number;
  cloudProvider: string;
  ssoEnable: boolean;
  extendedView: boolean;
  ssoType: string;
  legacyAuditsEnable: boolean;
  allowAdAuditCreation: boolean;
  selectAllUsers: boolean;
  auditReportFilter: boolean;
  restrictCertifyRevoke: boolean;
  advanceFilter: boolean;
  advanceAuditFilter: boolean;
  auditReviewStatusNotify: boolean;
  restrictMultipleDelegation: boolean;
  sendSlackNotification: boolean;
  enableAuditTemplate: boolean;
  syncUsers: boolean;
  entityId: string;
  url: string;
  clientId: string;
  companyId: any;
  error:any;
  // @propObject()
  // metaData : MetaData;
  @required()
  selfDelegation: boolean;
  @required()
  emailValue:boolean;
  @required()
  managerKey:string;
  @required()
  applicationProfileKey: string;
  @required()
  applicationRoleKey: string;
  showUndo: string;
}
// export class MetaData {
//   @prop()
//   managerKey:string;
//   @prop()
//   applicationProfileKey: string;
//   @prop()
//   applicationRoleKey: string;
 
// }

