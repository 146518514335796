import { User } from '../models/auth/user.model';
import { CookieService } from 'ngx-cookie-service';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Company } from '../models/company.model';
import { ChartData } from 'chart.js';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: "root" })
export class GlobalService {
  company: Company;
  user: User;
  renderer: Renderer2;

  private constructor(private cookieService: CookieService) {
    this.user = this.cookieService.get("userData") != '' ? this.decryptData(this.cookieService.get("userData"), environment.userSecretKey) : null;
    this.company = this.cookieService.get("company") != '' ? this.decryptData(this.cookieService.get("company"), environment.companySecretKey) : null;
  }

  public setCompany(key, value) {
    value = this.encryptData(value, environment.companySecretKey);
    this.cookieService.set(key, value, {
      path: '/',
    });

    this.company = this.decryptData(this.cookieService.get("company"), environment.companySecretKey);
  }

  public setUser(key, value) {
    value = this.encryptData(value, environment.userSecretKey);
    this.cookieService.set(key, value, {
      path: '/',
    });

    this.user = this.decryptData(this.cookieService.get("userData"), environment.userSecretKey);
  }

  public removeCompany(key) {
    this.cookieService.delete(key, '/');
    this.company = null;
  }

  public removeUser(key) {
    this.cookieService.delete(key, '/');
    this.user = null;
  }

  public setItem(key, value) {
    this.cookieService.set(key, value);
  }

  public getItem(key) {
    if (this.cookieService.get(key) == '') {
      return null;
    } else {
      return this.cookieService.get(key);
    }

  }
  public removeItem(key) {
    this.cookieService.delete(key)
  }
  public clear() {
    this.cookieService.deleteAll();
  }

  public adjustUTCDate(date: string | Date): Date {
    let d = new Date(date);
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  }

  getChartData(data, forLicenses = false) {
    // Simulating request from local data
    return of(data).pipe(
      map(values => this.prepareChartData(values, forLicenses))
    );
  }

  prepareChartData(chartData: { label: string, value: number }[], forLicenses) {
    return {
      labels: chartData.map(data => data.label),
      datasets: [
        {
          data: chartData.map(data => data.value),
          // backgroundColor: ['#0D98BA', '#607D88']
           backgroundColor: forLicenses ? ['#607D88', '#4CAF50'] : ['#4CAF50', '#607D88'] 
          // backgroundColor: ['#4b6674', '#b3d7e8']

        }
      ]
    } as ChartData;
  }

  adjustPaginatorMargin(element: HTMLElement , type): void {
    const paginatorContainer = element.querySelector('.mat-footer-row') as HTMLElement;
    const paginator = element.querySelector('.mat-paginator-range-actions') as HTMLElement;
    const marginRightPx =
    paginatorContainer.offsetWidth > element.offsetWidth ? paginatorContainer.offsetWidth - element.offsetWidth : 0;
    const marginRight = `${marginRightPx + (type === 'audit' ? 12 : -40)}px`;
    this.renderer?.setStyle(paginator, 'margin-right', marginRight);
  }


  encryptData(data: any, key: string): string {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  }
 
  decryptData(ciphertext: string, key: string): any {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    return JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
  }
}

