import { HttpErrorResponse } from '@angular/common/http';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { GlobalService } from '../shared/services/global.service';
import { SnackbarService } from '../shared/services/snackBarService.service';
import { MatSpinnerService } from '../shared/services/mat-spinner.service';

@Injectable()
export class PendingInterceptorService implements HttpInterceptor {
  private _pendingRequests = 0;

  get pendingRequests(): number {
    return this._pendingRequests;
  }

  private _pendingRequestsStatus: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  get pendingRequestsStatus(): Observable<boolean> {
    return this._pendingRequestsStatus.asObservable();
  }

  private _filteredUrlPatterns: RegExp[] = [];

  get filteredUrlPatterns(): RegExp[] {
    return this._filteredUrlPatterns;
  }

  constructor(router: Router, private globalService: GlobalService, private snackbar: SnackbarService, private matSpinnerService: MatSpinnerService) {
    router.events.subscribe(event => {

      if ((event instanceof NavigationError || event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof ActivationEnd)) {
        this._pendingRequestsStatus.next(false);
      }

      if (event instanceof NavigationStart) {
        this._pendingRequestsStatus.next(true);
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const shouldBypass = this.shouldBypass(req.url);

    if (!shouldBypass) {
      this._pendingRequests++;
      if (this.globalService.user?.accessToken) {
        let key = '$&ct-+$ez=e+^(eo(=$78-govv@juj^@rk7qrg=31j%=s*2$36';
        let data = this.globalService.user.accessToken + '|' + new Date().getTime();
        let secureValue = CryptoJS.AES.encrypt(data, key);

        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + this.globalService.user.accessToken)
            .set('IS-YOUATTEST', secureValue.toString())
        });
      }
      

      if (typeof req.url !== 'string') {
        console.error('Request URL is not a string:', req.url);
        return next.handle(req);
      }

      if (1 === this._pendingRequests) {
        this._pendingRequestsStatus.next(true);
      }
    }

    return next.handle(req).pipe(
      map(event => {
        return event;
      }),
      tap(res => {

        if (res.type === HttpEventType.Sent) {
          this.matSpinnerService.show();
        }
        if (res.type === HttpEventType.Response) {
          this.matSpinnerService.hide();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        
        if (error.error)
          this.matSpinnerService.hide();
        if (error.status == 309) {
          this.snackbar.open(error.error?.message, 'X', 'warn');
        }else{
          this.snackbar.open(error.error.message, 'Close', 'error');
        }
       
        return throwError(error);
      }),
      finalize(() => {
        if (!shouldBypass) {
          this._pendingRequests--;

          if (0 === this._pendingRequests) {
            this._pendingRequestsStatus.next(false);
          }
        }
      })
    );
  }

  private shouldBypass(url: string): boolean {
    return this._filteredUrlPatterns.some(e => {
      return e.test(url);
    });
  }
}

export function PendingInterceptorServiceFactory(router: Router, globalService: GlobalService, snackbar: SnackbarService, matSpinnerService: MatSpinnerService) {
  return new PendingInterceptorService(router, globalService, snackbar, matSpinnerService);
}

export let PendingInterceptorServiceFactoryProvider = {
  provide: PendingInterceptorService,
  useFactory: PendingInterceptorServiceFactory,
  deps: [Router, GlobalService, SnackbarService, MatSpinnerService]
};
