import { GlobalService } from './../../services/global.service';
import { ConnectorServiceService } from './../../services/connector-service.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, startWith, map } from 'rxjs';
import { SnackbarService } from '../../services/snackBarService.service';
import { Connector } from '../../models/connector.model';
import { MatSelectChange } from '@angular/material/select';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-create-connector',
  templateUrl: './create-connector.component.html',
  styleUrls: ['./create-connector.component.scss']
})
export class CreateConnectorComponent implements OnInit {
  myControl = new FormControl('');
  filteredOptions: Observable<any[]>;
  connectors: Connector[];
  customSelected: boolean = false;
  connectorForm: FormGroup;
  connector: Connector;
  isSubmitted: boolean = false;
  saleForce: boolean = false;
  pageType: string = 'add';
  selectcustom = [
    { type: 'Select a Resource', value: '' },
    { type: 'Salesforce', value: 'salesforce' },
    { type: 'AWS', value: 'AWS' },
    { type: 'Create a Custom Resource', value: 'custom-connector' },
  ];
  isDisabled = false;
  public hide = true;
  customFields: any[] = [
    { name: 'Custom Field1', value: 'custom1', disable: true, customField: true, selectedValue: '' },
    { name: 'Custom Field2', value: 'custom2', disable: true, customField: true, selectedValue: '' },
    { name: 'Custom Field3', value: 'custom3', disable: true, customField: true, selectedValue: '' },
    { name: 'Custom Field4', value: 'custom4', disable: true, customField: true, selectedValue: '' },
    { name: 'Custom Field5', value: 'custom5', disable: true, customField: true, selectedValue: '' },
  ];
  selectedOption  = 'Secret';
  mappingKeys: any;
  showPass: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CreateConnectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: RxFormBuilder,
    private connectorService: ConnectorServiceService,
    private snackbarService: SnackbarService,
    private globalService: GlobalService,
  ) {
    this.connector = new Connector();
  }

  ngOnInit(): void {
    this.connectorForm = this.formBuilder.formGroup(this.connector);

    this.pageType = this.data.pageType;
    
    if (this.pageType == 'edit') {
      this.connector = {...this.data.connector};
      if(this.connector.username){
        this.showPass= true;
        this.selectedOption ='password'
      }
      this.getMappingKeys();
      if (this.connector) {
        this.saleForce = true;
      }
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  onRadioChange(event: MatRadioChange) {
    if(event.value == 'password'){
      this.showPass = true;
    }else{
      this.showPass = false;
    }
  }

  Disabled(field) {
    if (field.name.length < 1) {
      this.snackbarService.open('Empty fields are not allowed.', 'X', 'error');
      return;
    }

    field.disable = !field.disable;
    this.isDisabled = false;
    this.customFields.filter(x => x.customField).forEach(x => {
      if (!x.disable) {
        this.isDisabled = true;
      }
    });
  }

  updateName(field: any, newName: string) {
    this.Disabled(field)
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.connectors.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option.value === 'Custom') {
      this.customSelected = true
    }
  }

  getMappingKeys() {
    
    let req = {
      id: this.connector.id
    }
    this.connectorService.getkays(req).subscribe(res => {
      this.mappingKeys = res.data;

      this.customFields = this.connector.mappingSchema.customFields || this.customFields;
      this.mappingKeys.youattest_keys.forEach(key => {
        if (!Object.keys(this.connector.mappingSchema).includes(key)) {
          this.connector.mappingSchema[key] = '';
        }
      });
    })
  }

  onSelectionChange(event: MatSelectChange) {
    if (event.value == 'custom-connector') {
      this.customSelected = true;
      this.saleForce = false;
    } else {
      this.customSelected = false;
      this.saleForce = true;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  EnableDisableConnection() {
    if(!this.showPass){
      this.connector.username ='';
      this.connector.password= '';
      
      this.connectorForm.get('username').clearValidators();
      this.connectorForm.get('username').updateValueAndValidity();
      this.connectorForm.get('password').clearValidators();
      this.connectorForm.get('password').updateValueAndValidity();
    }
    if (this.connector.resourceType == 'AWS') {
      this.connectorForm.get('apiUrl').clearValidators();
      this.connectorForm.get('apiUrl').updateValueAndValidity();
      this.connectorForm.get('regionName').setValidators([Validators.required]);
      this.connectorForm.get('regionName').updateValueAndValidity();  
    } else {
      this.connectorForm.get('regionName').clearValidators();
      this.connectorForm.get('regionName').updateValueAndValidity();
      this.connectorForm.get('apiUrl').setValidators([Validators.required]);
      this.connectorForm.get('apiUrl').updateValueAndValidity(); 
    }

    if (this.connector.resourceType != "custom-connector" && this.pageType == 'add') {
      this.isSubmitted = true;
      this.connectorForm.markAllAsTouched();
      if (this.connectorForm.invalid) return;
      this.connector.companyId = this.globalService.company.id;
      this.connector.status = 1;
      this.connectorService.createCompanyConnector(this.connector).subscribe(res => {
        this.snackbarService.open(res.message, 'X', 'success');
        this.dialogRef.close();
      })
    }
    else if (this.pageType == 'edit') {
      
      this.isSubmitted = true;
      if (this.connector.mappingSchema.username === '') {
        this.snackbarService.open("Please map the 'Username' field", 'Close', 'warn');
        return;
      }
      this.connector.mappingSchema.customFields = this.customFields;
      this.connector.companyId = this.globalService.company.id;
      this.connectorService.updateCompanyConnector(this.connector).subscribe(res => {
        this.snackbarService.open(res.message, 'X', 'success');
        this.dialogRef.close();
      })
    }
  }
  
  testSaleforceConnection(){
    let request = {
      id : this.connector.id,
      clientId : this.connector.clientId,
      clientSecret : this.connector.clientSecret,
      username : this.connector.username,
      password : this.connector.password
    };
    this.connectorService.validateCredentials(request).subscribe(res => {
      this.snackbarService.open(res.message, 'X', 'success');
    })
  }

}
